import React, { useEffect, useState, useRef } from 'react';
import { useForm } from '../forms/useForm';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { ConfigServer } from "../../data/config";
import moment from 'moment';
import { useModal } from "react-morphing-modal";
import { usePaginationCash } from "../pagination/usePaginationCash";
import {getAllBranches } from '../../services/commercialLine/commercialLine'
import {getDetailCash} from '../../services/cashFlow/cashFlow'
import {createCash} from '../../services/cashFlow/cashFlow'
import * as XLSX from 'xlsx';



export const useCash = () => {

    let id_branch = localStorage.getItem('branchId');
    let _type_client = localStorage.getItem('url_helen');


    const [viewTab, setViewTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    //indicadores
    const [cancell, setCancell] = useState(0);
    const [discountT, setDiscountT] = useState(0);
    const [orderFirst, setOrderFirst] = useState(0);
    const [orderLast, setOrderLast] = useState(0);
    const [idCashFlow, setIdCashFlow] = useState(0);
    const [totalCancell, setTotalCancell] = useState(0);
    //historic cash flow
    const [dataHistoric, setDataHistoric] = useState([]);
    const [dateInit, setDateInit] = useState(new moment().format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(new moment().format('YYYY-MM-DD'));
    const [typeModal, setTypeModal] = useState("ViewCas");
    const [modalC, setModalC] = useState(false);
    const [typeAmoun, setTypeAmoun] = useState([]);
    const [amountfin, setAmountfin] = useState([]);
    const [totalPaymets, setTotalPaymets] = useState(0);
    const numberCash = 8;
    const [branches, setBranches] = useState([]);
    const [dataUser, setDataUser] = useState([])
    const [sumPayMethods, setSumPayMethods] = useState(0)
    const [totalPatients, setTotalPatients] = useState(0);

    //*CONFIGURATION FINANCE
    let _valid_Finance = localStorage.getItem('finance');
    let _finance2 = localStorage.getItem('finance_view');
    let _validationFinance = (_valid_Finance === "True" &&  _finance2 === "False") ? true : false

    //*PRINT TICKET
    const [loadingPrint, setLoadingPrint] = useState(false);

    //*DETAIL REPORT
    const [datafinally, setDatafinally] = useState([]);
    const [dataDevolution, setDataDevolution] = useState(0);
    const [listC, setListC] = useState(0)
    const [loadingCreate, setLoadingCreate] = useState(false)

    //*PRINT TICKET
    const [modalPrint, setModalPrint] = useState(false);

    //*PREVIEW CASH
    const [modalPreview, setModalPreview] = useState(false);

    //*FILTER REPORTS
    const [viewFormFilters, setViewFormFilters] = useState(true);

    //*FINAL REPORT 
    const [dataReport, setDataReport] = useState([]);
    const [key, setKey] = useState(0);
    const [dataPaymets, setDataPaymets] = useState([]);
    const [dataCancell, setDataCancell] = useState(0);
    const [dataDiscount, setDataDiscount] = useState(0);
    const [dataPatient, setDataPatient] = useState(0);
    const [dataMDiscount, setDataMDiscount] = useState(0);
    const [total_devolution, setTotal_devolution] = useState(0)


    useEffect(() => {
        getAmount();
        handleHistoric(true);
        getDetailCreditCash();
        getBranches();
    }, []);

    useEffect(() => {
        if (idCashFlow > 0) {
            printTicket(idCashFlow);
            downloadExcel(idCashFlow);

        }
    }, [idCashFlow]);

    const toggleCash = () => {
        setModalC(!modalC);
    }

    //*REF
    const modalFullRef = useRef(null);

    //*FORM
    const [formCash, handleInputChangeCash, resetCash, handleUpdateValuesCash, handleSelectValuesCash] = useForm({
        capture_amount: "",
        comments: "",
        mDateRange1: "",
        mDateRange2: "",
        mSelectBranch: 0,
        ids_users: 0,
        ids_branches: 0,
        type_company: null
    });

    const {
        capture_amount,
        comments,
        mDateRange1,
        mDateRange2,
        mSelectBranch,
        type_company,
        ids_users
    } = formCash;

      //*PRINT TICKET
      const toggleModalPrint = () => {
          setModalPrint(!modalPrint);
      }
  
      const handleViewTickets = () => {
          toggleModalPrint()
      }
  
      //*PREVIEW CASH 
      const toggleModalPreview = () => {
          setModalPreview(!modalPreview);
      }
  
      const handleViewPreview = () => {
          toggleModalPreview()
      }

   

      //*OPEN FINAL REPORT
      const { close, modalProps, open } = useModal({
          onClose: () => setKey(key => key + 1),
          background: "#fff"
  
      });
  
      const handleReport = () => {
          open(modalFullRef);
      }


      //*HISTORIC CASH
      const previewCash = async (nim) => {
        setTypeModal("ViewCas");
        toggleCash();
      }

      
     //*ACTIONS MENU
    const navigatePrev = () => {
        let tab = viewTab;
        tab = tab - 1;
        setViewTab(tab);
        handleUpdateValuesCash({
            capture_amount: 0,
            input2: "",
            input3: "",
            input4: "",
            comments: ""
        })
    };


    //*BRANCHES
    useEffect(() => {
        if (branches.length > 0) {

            let _find = branches.find(x => x.value === parseInt(id_branch))
            handleUpdateValuesCash({
                ...formCash,
                mSelectBranch: _find
            });
        }
    }, [branches]);


    const getBranches = async () => {
        const branches = await getAllBranches();
        if (branches !== null && branches.length > 0) {
            let _allOption = {
                value: 0,
                label: "TODAS LAS SUCURSALES"
            };
    
            let _newBranches = [_allOption]; 
    
            branches.forEach((branch) => {
                _newBranches.push({
                    value: branch.id_branch,
                    label: branch.name
                });
            });
    
            setBranches(_newBranches);
        } else {
            setBranches([]);
        }
    }
   
    //*DETAIL CASH 
    const getAmount = async () =>
        {
            const detailAmount = await getDetailCash(id_branch);

            if (detailAmount !== null) {

                let _restTotal = 0;
                let _sum_Global = 0;
            
                const list = detailAmount?.workOrderCash.reduce((_delete, _find) => {

                    if (_find.id_pay_method === 10 && _find.fully_paid === true) {
                        return _delete;  
                    }
                    
                    if (_find.id_pay_method === 10 && _find.fully_paid === false) {
                        _restTotal += _find.amout;
                    }
            
                    _sum_Global += _find.amout;
            
                    const _valid = {
                        id_pay_method: _find.id_pay_method,
                        pay_method: _find.pay_method,
                        total: (_find.id_pay_method === 10) ? Math.round(_find.amout) : Math.round(_find.amout),
                        fully_paid: _find.fully_paid
                    };
            
                    _delete.push(_valid);

                    return _delete;
                }, []);
            
                setDatafinally(list);
                setListC(_restTotal);
                setSumPayMethods(_sum_Global);
                setCancell(detailAmount?.total_work_order_cancels);
                setDiscountT( Math.round(detailAmount?.total_discounts));
                setOrderFirst(detailAmount?.id_work_order_first);
                setOrderLast(detailAmount?.id_work_order_last);
                setTotalCancell(detailAmount?.cancell);
                setDataDevolution(detailAmount?.cancellAndIva);
                setTotalPatients(detailAmount?.total_work_orders)
            }
            else 
            {
                setDatafinally([]);
                setListC(0);
                setSumPayMethods(0);
                setCancell(0);
                setDiscountT(0);
                setOrderFirst(0);
                setOrderLast();
                setTotalCancell();
                setDataDevolution(0);
                setTotalPatients(0);
            }
        }

    //*GROUP BY METHODS    
    useEffect(() => {
        if (datafinally.length >= 1) {

            let _array = datafinally;
            let _nuevoObjeto = [];
            let _indexObject = [];

            _array.forEach(x => {
                console.log(x);
                if (!_nuevoObjeto.hasOwnProperty(x.pay_method)) {
                    _nuevoObjeto[x.pay_method] = {
                        Amount: 0,
                        AmountCapture: 0,
                    }
                    _indexObject.push(x.pay_method);
                }
                _nuevoObjeto[x.pay_method].Amount = _nuevoObjeto[x.pay_method].Amount + Math.round(x.total);
            })

            setAmountfin([_nuevoObjeto])
            setTypeAmoun([..._indexObject])
        }
        else {
            setTypeAmoun([]);
            setAmountfin([]);
        }

    }, [datafinally, dataDevolution, listC])

    //*CREATE CASH
    const saveCreate = async () =>
        {
            setLoadingCreate(true)
            
            let _arrayNew = amountfin[0]
            let _e = "EFECTIVO"
            let _notAmount = _arrayNew[_e]
            let _valid =_arrayNew[_e].Amount
            // ? CONFIGURATION TYPE CLIENT
            let _valid_amount = _type_client === "https://insan.helen-sw.com" ? _valid : formCash.capture_amount

           const viewCash = await createCash(formCash.comments,_notAmount,_valid,_valid_amount,orderFirst,orderLast,id_branch);
           
            if (viewCash > 0)
            {
                setLoadingCreate(false)
                setIdCashFlow(viewCash);
                createSweet("success", "success", "Corte de Caja realizado");
                updateCash();  
            }
            else {
                setLoadingCreate(false)
                createSweet("warning", "warning", "No se genero correctamente el corte");
            }
    }

    const updateCash = () => {
        handleUpdateValuesCash({
            capture_amount: 0,
            input2: "",
            input3: "",
            input4: "",
            comments: ""
        })
        setViewTab(1);
        getAmount();
        setTypeAmoun([]);
        setAmountfin([]);
    }

    //*PRINT TICKET
    const printTicket = async (id_cash_flow,_valid) => {

        if (id_cash_flow > 0) {
            setLoadingPrint(true)

            let token = localStorage.getItem("token");

            await fetch(ConfigServer.serverUrl + "/api/CashFlow/PrintTicket/" + id_cash_flow, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
                .then(response => {
                    if (response.status !== 200) {
                        createSweet("warning", "warning", "No se descargo el Ticket");
                        return;
                    }
                    if (_valid) {
                        setLoadingPrint(false)
                        toggleModalPrint();
                    }
                   
                    return response.blob();
                })
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "ticket.pdf")
                    document.body.appendChild(link);
                    link.style.display = 'none';
                    link.click();

                    setIdCashFlow(0);
                })
        }
    }

    // ? DOWNLOAD CSV
    const downloadExcel = async (_idCash) => 
        {
        let token = localStorage.getItem("token");
        await fetch(ConfigServer.serverUrl + `/api/WorkOrder/DownloadWorkOrderPayments/${_idCash}`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/vnd.ms-excel"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el archivo excel");
                    return;
                }
    
                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
    
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Reporte.xlsx");
    
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }

    //#region Historic cashFlow
    //*HISTORY OF CASH
    const handleHistoric = async (filters = false) => {

        let _body = {};
        if (filters === false) {
            _body = {
                "date_init": "",
                "date_end": "",
                "type": "all"
            }
        } else {
            _body = {
                "date_init": dateInit,
                "date_end": dateEnd,
                "type": "filter"
            }
        }
        let _listTemp = await handleRequest("POST", "WorkOrder/DetailCash", "cash", _body);

        if (_listTemp !== null) {
            setDataHistoric([..._listTemp]);
        } else {
            setDataHistoric([]);
        }
    }

    const changeDate = (data, type) => {
        let _fecha = new moment(data._d).format('YYYY-MM-DD');
        if (type === "init") {
            setDateInit(_fecha)
        } else {
            setDateEnd(_fecha)
        }
    }
    //#endregion Historic cashFlow


    //#region registro cash
    //*METHOD FINAL REPORT
    const getReport = (e, search = true) => {
        e.preventDefault();

        if (search) {

            let query = "";
            let _mfrom = (mDateRange1) ? moment(mDateRange1).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            let _mto = (mDateRange2) ? moment(mDateRange2).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            let _idBranch = mSelectBranch.value;
            let _typeUser = type_company == null ? null : type_company.value;
            let _idUser = ids_users.value;
           
            
            if (_mfrom !== "") {
                query += `${_idBranch}?date_from=${_mfrom}`;
            }

            if (_mto !== "") {
                query += `&date_to=${_mto}`;
            }

            if (_idBranch !== "" && _mfrom === "") {
                query += `${_idBranch}`;
                console.log("-");
            }

            if (type_company !== null && _mfrom.length === 0  && _mto.length === 0 ) {
             //  query += `?type_company=${_typeUser}`;   
               console.log("?");
            }

            if (type_company == null || _mfrom.length !== 0  || _mto.length !== 0 ) {
                if (_typeUser > 0) {
                    query += `&type_company=${_typeUser}`; 
                    console.log("====");
  
                }
                else {
                    if (_idUser === undefined) {           
                    }
                    else {
                        console.log("aqui pasa?3");
                        query += `&id_user=${_idUser}`; 
                        setDataUser([{ "value": 0, "label": "Todos" }])
                    }
                }
             }

            //  if (type_company !== null || _mfrom.length === 0  || _mto.length === 0 ) {
            //     query += `&type_company=${_typeUser}`;  
            //     console.log("hhh");
                
            //  }
            getDetailCreditCash(true, query)
        }
    }

    const getDetailCreditCash = async (_search = false, _filter) => {
        setLoading(true);
        var _listTmp = {};
        let _alterUrl = "";
    
        if (_search === false) {
            _listTmp = await handleRequest("GET", `WorkOrder/DetailCreditCash/${id_branch}` + _alterUrl, 'Report');
        } else {
            _listTmp = await handleRequest("GET", `WorkOrder/DetailCreditCash/` + _filter, 'Report');
        }
    
        if (_listTmp !== null) {
            setLoading(false);

            console.log("---", _listTmp);
    
            let _arrayNew = [];
            let startItems = 0;

            let endItems = numberCash;
            let aux = numberCash * currentPage1;
            endItems = aux;
            startItems = endItems - numberCash;
    
            let _amountGlobal = [
                { id_pay_method: 1, pay_method: "EFECTIVO", amount: 0 },
                { id_pay_method: 2, pay_method: "TARJETA DE CRÉDITO", amount: 0 },
                { id_pay_method: 4, pay_method: "TARJETA DE DÉBITO", amount: 0 },
                { id_pay_method: 8, pay_method: "TRANSFERENCIA ELECTRÓNICA DE FONDOS", amount: 0 },
                { id_pay_method: 10, pay_method: "CREDITO", amount: 0 }
            ];
    
            let decimals = 2;
         const companyNamesMap = {}

            _listTmp.workOrderDetailCash.forEach((_find, _key) => {

                //!CONFIGURATION FINANCE
            if (_valid_Finance === "True") {
                if (_finance2 === "False") {
                    if (_find.complete_information === "True") {
                        return;   
                    }
                }
            }

                let posicion = _key + 1;
                let visibleTable = posicion > startItems && posicion <= endItems;

                if (_find.id_user !== null && _find.id_user !== 0) {
                    const companyName = _find.user;
            
                    if (!companyNamesMap.hasOwnProperty(companyName)) {
                        companyNamesMap[companyName] = true; 
            
                        const nuevo = {
                            value: _find.id_user,
                            label: companyName,
                        };
                        setDataUser(prevDataUser => [...prevDataUser, nuevo]);
                    }
                }
            
                let { nameMethod, amount, nim, fech, name, company, status, user, abreviationName, abreviationPaternal, abreviationMaternal, id_user, ajuste, paid,fech_Adjust,fech_admision,complete_information,name_company,is_invoice,name_branches,name_Preloaded_discount,percentagePreloaded_discount,id_company} = _find;
    
                let payment = {
                    creditCard: nameMethod === "TARJETA DE CRÉDITO" ? amount : 0,
                    cash: nameMethod === "EFECTIVO" ? amount : 0,
                    debitCard: nameMethod === "TARJETA DE DÉBITO" ? amount : 0,
                    electronicTransfer: nameMethod === "TRANSFERENCIA ELECTRÓNICA DE FONDOS" ? amount : 0,
                    credit: nameMethod === "CREDITO" ? amount : 0,
                }
    
                let existingOrderIndex = _arrayNew.findIndex(objeto => objeto.nim === _find.nim);
    
                if (existingOrderIndex !== -1) {
                    if (!_find.ajuste) {
                        _arrayNew[existingOrderIndex].amount += amount;
                    }
                    Object.keys(payment).forEach(method => {
                        _arrayNew[existingOrderIndex][method] += payment[method];
                    });
                } else {
                    _arrayNew.push({
                        ...payment,
                        nim,
                        hour: fech,
                        namePatiente: name,
                        name_branches: name_branches,
                        type: company ? "Empresas" : "Público General",
                        status: status === 0 ? "Cancelado" : "Vigente",
                        method: nameMethod,
                        amount: (!_find.ajuste ? amount : 0),
                        user,
                        id_user,
                        is_fullPaid: paid === false ? "PENDIENTE" : "LIQUIDADO",
                        visibleTable,
                        is_adjustP: _find.is_adjustP,
                        abreviationName,
                        abreviationPaternal,
                        abreviationMaternal,
                        gatos: _find.status === 0 ? 0 : 1,
                        nameUserAdjust: _find.nameUserAdjust,
                        nameCommont: _find.nameCommont,
                        amountDiscount: company ? "" : _find.amountDiscount,
                        amountPercentag1: _find.amountPercentag1,
                        codeD: _find.codeD,
                        amountPercentag: _find.amountPercentag,
                        ajuste,
                        paid,fech_Adjust,
                        fech_admision,
                        complete_information,
                        name_company,
                        is_invoice,
                        name_Preloaded_discount,
                        percentagePreloaded_discount,
                        id_company
                    })
                }
                Object.keys(payment).forEach(method => {
                    if (_find.status === 0 ) {
                        return;
                    }
                    
                    let id_pay_method = _find.id_pay_method;
                    let methodAmount = payment[method];

                    if (!_find.company ) {
                    if (id_pay_method === 10 && _find.is_fullPaid) {
                        return; 
                    } 
                    }
              
                    let globalIndex = _amountGlobal.findIndex(item => item.id_pay_method === id_pay_method);
                    
                    if (globalIndex !== -1) {
                        _amountGlobal[globalIndex].amount += methodAmount;
                    }
                });
            });
    
        
            let _totalCancell = _arrayNew.reduce((total, _movement) => {
                return _movement.status === "Cancelado" ? total + _movement.amount : total;
            }, 0);
    
           
            let _totalDiscount = _arrayNew.reduce((total, _f) => {
                let _finA = _f.amountDiscount;
                if (_finA !== 0 && _finA !== "" && _finA !== null) {
                    return total + _f.amountDiscount;
                } else {
                    return total;
                }
            }, 0);
          
            let _totalGlobalMovements = _amountGlobal.reduce((total, _find) => {
                console.log("nuevo",total);
                return total + _find.amount;
            }, 0);
    
            let _subtotal_ = _totalGlobalMovements;
            _subtotal_ = parseFloat(_subtotal_.toFixed(decimals));

           
            setDataReport(_arrayNew);
            setDataPaymets(_amountGlobal);
            setDataCancell(_totalCancell);
            setDataMDiscount(_totalDiscount)
            setDataDiscount(_listTmp.total_discount);
            setDataPatient(_listTmp.total_patien);
            setTotal_devolution(_listTmp.total_devolution)
            setTotalPaymets(_subtotal_);

        }
    }
    //*END METHOD FINAL REPORT

    const handleClean =() => {

        handleUpdateValuesCash({
            mDateRange1: "",
            mDateRange2: "",
            type_company: null,
            mSelectBranch: 1
        });
        getDetailCreditCash(false)
    }
    //#endregion registro cash

    //*PAGINATION FINAL REPORT 
    const { totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1 } = usePaginationCash(dataReport.length, numberCash);

    useEffect(() => {
        let startItems = 0;
        let endItems = numberCash;

        let aux = numberCash * currentPage1;

        endItems = aux;

        startItems = endItems - numberCash;

        let listTmp = [];

        dataReport.forEach((data, key) => {
            //!CONFIGURATION FINANCE
            if (_valid_Finance === "True") {
                if (_finance2 === "False") {
                    if (data.complete_information === "True") {
                        return;   
                    }
                }
            }
         
            let posicion = key + 1;
            let visibleTable = false;

            if (posicion > startItems && posicion <= endItems) {
                visibleTable = true;
            }

            listTmp.push({
                ...data,
                visibleTable: visibleTable
            });
        });

        setDataReport(listTmp);

    }, [totalPageCount1, currentPage1]);


    //*PDF FINAL REPORT 
    const printCashClosing = async () => {

        let valuesBranch = formCash.mSelectBranch
        let nameBranch = valuesBranch.label

        const modifiedDataReport = dataReport.map((_find, _key) => {
            if (_validationFinance) {
                let _b = _validationFinance ? (_key + 1) : _find.nim;
                return { ..._find, nim: _b };
            }
            return null; 
        }).filter(item => item !== null); 
        
        let data = {
            workOrderDetailCash: _validationFinance? modifiedDataReport : dataReport ,
            listMovements: dataPaymets,
            total_devolution: dataCancell,
            total_discount: dataDiscount,
            total_patien: dataPatient,
            name_branch: nameBranch,
            date_cash: mDateRange1,
            amount_global: totalPaymets,
            amount_discount: dataMDiscount.toFixed(2)
        };
        
        data.workOrderDetailCash.forEach(workOrderCash => {
            if (workOrderCash.status === "Cancelado") {
                workOrderCash.status = 0;
            } else {
                workOrderCash.status = 1;
            }

            if (workOrderCash.is_fullPaid === "PENDIENTE") {
                workOrderCash.is_fullPaid = false;
            } else {
                workOrderCash.is_fullPaid = true;
            }
        })

        let raw = JSON.stringify(data);
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/CashFlow/PrintCashClosing", {
            method: 'POST',
            body: raw,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {

                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el PDF");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Corte de Caja.pdf")
                document.body.appendChild(link);
                link.style.display = 'none';
                link.click();
            });
    }


    //*EXCEL FINAL REPORT  
    function exportToExcel(nimDataExcel) {
        let _Report = [];
        let _array = nimDataExcel;
        let totalAmount = 0; 

    
        _array.forEach((_find, key) => {
            let _a = _validationFinance ? "#" : "Solicitud(NIM)";
            let _b = _validationFinance ? (key + 1) : _find.nim;
    
            let _new_amount = _find.status === "Cancelado" ? 0 : _find.amount;
            totalAmount += _new_amount; 
    
            let reportObject = {
                [_a]: _b,
                "Nombre": _find.namePatiente,
                "Fecha": _find.fech_admision,
                "Sucursal": _find.name_branches,
                "Compañia": _find.type,
                "Nombre de la empresa": _find.type === "Empresas" ? _find?.name_company : "N/A" ,
                "Efectivo": _find.cash,
                "Credito": _find.credit,
                "Tarjeta de Crédito": _find.creditCard,
                "Tarjeta de Debito": _find.debitCard,
                Transferencia: _find.electronicTransfer,
                "Tipo de descuento":_find?.name_Preloaded_discount !== "" ?_find?.name_Preloaded_discount :_find?.nameCommont !== ""?_find?.nameCommont:"N/A",
                "Porcentaje de descuento":  _find?.name_Preloaded_discount !== "" ?_find?.percentagePreloaded_discount :_find?.nameCommont !== ""?_find?.amountPercentag:"N/A",
                "Usuario que realizo admisión": _find.user,
                "Usuario que realizo recuperación": _find.nameUserAdjust,
                "Estatus de facturación": _find.is_invoice,
                "Estatus": _find.status,
                "Total": _new_amount
            };
    
            _Report.push(reportObject);
        });
    
        let totalRow = {
            "Nombre": "",
            "Fecha": "",
            "Sucursal": "",
            "Compañia": "",
             "Nombre de la empresa":"",
            "Efectivo": "",
            "Credito": "",
            "Tarjeta de Crédito": "",
            "Tarjeta de Debito": "",
            Transferencia: "",
            "Tipo de descuento": "",
            "Porcentaje de descuento": "",
            "Usuario que realizo admisión": "",
            "Usuario que realizo recuperación": "",
            "Estatus de facturación":"",
            "Estatus": "TOTAL GLOBAL",
            "Total": totalAmount 
        };
    
        _Report.push(totalRow); 
    
        const worksheet = XLSX.utils.json_to_sheet(_Report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileName = 'Repote_Corte_de_caja.xlsx';
    
        if (typeof window !== 'undefined' && window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
        } else {
            const url = window.URL.createObjectURL(dataBlob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    //*NUMBER FORMAT
    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));
  
        decimals = decimals || 0;
  
        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }
  
        cantidad = '' + cantidad.toFixed(decimals);
  
        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;
  
        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');
  
        return cantidad_parts.join('.');
    }

    //*FORMATTER
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })

    //*CONEXIÓN
    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 404) {
            createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }

    return {
        //*CONFIGURATION CASH
        navigatePrev, viewTab, formCash,capture_amount,handleInputChangeCash,loading, 

        //*INDICATORS
        cancell, discountT, comments, saveCreate, formatter, totalCancell,totalPatients,

        //*HISTORIC
        dataHistoric,dateInit, dateEnd, changeDate, handleHistoric,previewCash, toggleCash, typeModal, modalC,typeAmoun, amountfin,

        //*FINAL REPORT
        dataReport, modalFullRef, handleReport, close, modalProps,totalPageCount1, currentPage1, nextPage1, previousPage1, goToPage1,dataPaymets, dataCancell, 
        dataDiscount, dataPatient, dataDevolution,mDateRange1, mDateRange2, getReport, mSelectBranch, handleSelectValuesCash,
        viewFormFilters, printCashClosing,branches, totalPaymets,exportToExcel,

        //*FINAL REPORT TYPE COMPANY
        type_company,handleClean,dataMDiscount,dataUser,ids_users,number_format,sumPayMethods,

        //*PRINT TICKETS
        modalPrint,toggleModalPrint,handleViewTickets,printTicket,loadingPrint,loadingCreate,

        //*PREVIEW PRINT 
        modalPreview,toggleModalPreview,handleViewPreview,downloadExcel,

        // ? CONFIGURATION
        _type_client
    }
}
